/**
 * Copyright Ara Intelligence, 2019 - 2022.
 */

import React from 'react'
import Layout from './../../components/layout/layout.js'
import Seo from "./../../components/seo"
import { graphql } from "gatsby"
import { css } from "@emotion/react";
import { MDXRenderer } from 'gatsby-plugin-mdx';
import './blog-post.css';

const BlogPost = ({data}) => {
    const post = data.mdx;
	const top_headings = post?.headings.filter( x => x.depth === 1);
	const SEOTags = data.mdx?.frontmatter.tags.split(',') || ["AJ"];
    const seoDescription = data.mdx.frontmatter.description || data.mdx.excerpt;
    const lastUpdatedTime = post.frontmatter.last_updated || post.parent.modifiedTime;

    return (
        <Layout>
			<Seo 
				title={post.frontmatter.title}
				keywords={SEOTags}
				description={seoDescription}
				image={data.mdx.frontmatter.poster_image}
			/>
            <div className="">
                <div className="" css={css``}>
                    {/* <div className="hidden xl:block" css={css`font-size: 0.8rem;`}>grid md:grid-cols-3 w-screen md:w-5/6 lg:w-full
                        <p className="text-muted">TABLE OF CONTENTS</p>
                        <ul>                        
                            {top_headings.map((node,idx) => {
                                return <li key={idx}>{node.value}</li>
                            })}
                        </ul>w-full md:w-5/6 lg:w-full md:col-span-2 mx-2
                        prose => font-sans text-left text-base max-w-prose sm:text-sm md:text-base lg:text-base
                    </div> */}

                    <div className="flex justify-center">
                        <article className='
                                prose md:prose-sm lg:prose-base prose-neutral break-words text-left
                                prose-h1:text-blue-600
                            '>
                            <small>Created: {post.frontmatter.date}</small>
                            <br />
                            <small>Last Updated: {lastUpdatedTime}</small>
                            
                            <div className="">
                                <MDXRenderer className="">
                                    {post.body}
                                </MDXRenderer>
                            </div>
                        </article>
                    </div>
                </div>            
            </div>			
        </Layout>
    )
};

export default BlogPost;

export const query = graphql`
    query($slug: String!) {
        mdx( fields: { pathName: { eq: $slug }}) {
            body
            frontmatter {
				title
				tags
                date(formatString: "MMMM DD, YYYY")
				poster_image
            }
            headings {
                value
                depth
            }
			timeToRead
			excerpt
            parent {
                ... on File {
                    id
                    name
                    modifiedTime(formatString: "MMMM DD, YYYY")
                }
            }
        }
    }
`